<a
  mat-list-item
  [routerLink]="['/outhouse-reviewer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.OUTHOUSE_REVIEWER], false, false)"
>
  <mat-icon matListIcon>zoom_in</mat-icon>
  Outhouse Review
</a>

<a
  mat-list-item
  [routerLink]="['/sublimation-reviewer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.SUBLIMATION_REVIEWER], false, false)"
>
  <mat-icon matListIcon>zoom_in</mat-icon>
  Sublimation Review
</a>

<a
  mat-list-item
  [routerLink]="['/sticker-reviewer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.STICKER_REVIEWER], false, false)"
>
  <mat-icon matListIcon>zoom_in</mat-icon>
  Sticker Review
</a>

<a
  mat-list-item
  [routerLink]="['/outhouse-designer']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.isOuthouseManager() ||
    permissionsService.restrictToRoles([RoleId.OUTHOUSE_DESIGNER], false, false)
  "
>
  <mat-icon matListIcon>format_paint</mat-icon>
  Outhouse Design
</a>

<a
  mat-list-item
  [routerLink]="['/mid-tier-designer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.MID_TIER_DESIGNER], false, false)"
>
  <mat-icon matListIcon>format_paint</mat-icon>
  Mid-tier Design
</a>

<a
  mat-list-item
  [routerLink]="['/sticker-designer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.STICKER_DESIGNER], false, false)"
>
  <mat-icon matListIcon>palette</mat-icon>
  Sticker Design
</a>

<a
  mat-list-item
  [routerLink]="['/sublimation-designer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.SUBLIMATION_DESIGNER], false, false)"
>
  <mat-icon matListIcon>palette</mat-icon>
  Sublimation Design
</a>

<a
  mat-list-item
  [routerLink]="['/cropper']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.CROPPER], false, false)"
>
  <mat-icon matListIcon>crop</mat-icon>
  Crop
</a>

<a
  mat-list-item
  [routerLink]="['/booth-designer']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.BOOTH_DESIGNER], false, false)"
>
  <mat-icon matListIcon>format_paint</mat-icon>
  Booth Design
</a>

<a
  mat-list-item
  [routerLink]="['/labeler']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.LABELER], false, false)"
>
  <mat-icon matListIcon>label_important</mat-icon>
  Label
</a>

<h3
  matSubheader
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.PRINT_MANAGER
    ])
  "
>
  Overview
</h3>
<a
  mat-list-item
  [routerLink]="['/orders']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.RETAIL_MANAGER
    ])
  "
>
  <mat-icon matListIcon>receipt</mat-icon>
  Orders
</a>
<a
  mat-list-item
  [routerLink]="['/order-products']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.PRINT_MANAGER,
      RoleId.RETAIL_MANAGER
    ])
  "
>
  <mat-icon matListIcon>shopping_cart</mat-icon>
  <span
    *ngIf="expeditedOverviewTotal > 0"
    matBadge="{{ expeditedOverviewTotal }}"
    matBadgeOverlap="false"
    matBadgeColor="warn"
    >Order Products</span
  >
  <span *ngIf="expeditedOverviewTotal <= 0">Order Products</span>
</a>
<a
  mat-list-item
  [routerLink]="['/invoices']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.CUSTOMER_SERVICE_MANAGER, RoleId.CUSTOMER_SERVICE])
  "
>
  <mat-icon matListIcon>payment</mat-icon>
  Addon Invoices
</a>
<a
  mat-list-item
  [routerLink]="['/order-invoices']"
  [queryParams]="{ filterSources: 'backend', filterStatus: 'all' }"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([RoleId.SUPERADMIN, RoleId.ADMIN, RoleId.RETAIL_MANAGER]) ||
    (createOrderAccessUsers && createOrderAccessUsers.includes(permissionsService.user.id))
  "
>
  <mat-icon matListIcon>payment</mat-icon>
  Order Invoices
</a>
<a
  mat-list-item
  [routerLink]="['/needs-note-review']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.ORDER_ASSISTANT
    ])
  "
>
  <mat-icon matListIcon>feedback</mat-icon>
  <span *ngIf="needsNoteReviewTotal > 0" matBadge="{{ needsNoteReviewTotal }}" matBadgeOverlap="false"
    >Needs Note Review</span
  >
  <span *ngIf="needsNoteReviewTotal <= 0">Needs Note Review</span>
</a>
<a
  mat-list-item
  [routerLink]="['/special-treatment-order-products']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.CUSTOMER_SERVICE_MANAGER, RoleId.CUSTOMER_SERVICE])
  "
>
  <mat-icon matListIcon>notification_important</mat-icon>
  <span
    *ngIf="specialTreatmentOverviewTotal > 0"
    matBadge="{{ specialTreatmentOverviewTotal }}"
    matBadgeOverlap="false"
    class="special-treatment"
    >Special Treatments</span
  >
  <span *ngIf="specialTreatmentOverviewTotal <= 0">Special Treatments</span>
</a>

<a
  mat-list-item
  [routerLink]="['/stuck-in-design']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.INHOUSE_DESIGNER,
      RoleId.PRINT_MANAGER,
      RoleId.ORDER_ASSISTANT
    ])
  "
>
  <mat-icon matListIcon>lock_clock</mat-icon>
  <span>Stuck in Design</span>
</a>

<h3
  matSubheader
  *ngIf="
    permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.CUSTOMER_SERVICE_MANAGER, RoleId.CUSTOMER_SERVICE])
  "
>
  Customer Service
</h3>
<a
  mat-list-item
  [routerLink]="['/customer-service']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.CUSTOMER_SERVICE_MANAGER, RoleId.CUSTOMER_SERVICE])
  "
>
  <mat-icon matListIcon>headset_mic</mat-icon>
  <span
    *ngIf="customerServiceTotal > 0"
    matBadge="{{ customerServiceTotal }}"
    matBadgeOverlap="false"
    matBadgeColor="accent"
    [class.waiting-for-customer]="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER])"
    >Customer Service</span
  >
  <span *ngIf="customerServiceTotal <= 0">Customer Service</span>
</a>
<a
  mat-list-item
  [routerLink]="['/email-for-picture']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.ORDER_ASSISTANT
    ])
  "
>
  <mat-icon matListIcon>email</mat-icon>
  <span
    *ngIf="emailForPictureTotal > 0"
    matBadge="{{ emailForPictureTotal }}"
    matBadgeOverlap="false"
    class="email-for-picture"
    >Email for Picture</span
  >
  <span *ngIf="emailForPictureTotal <= 0">Email for Picture</span>
</a>
<a
  mat-list-item
  [routerLink]="['/email-for-subject']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.ORDER_ASSISTANT
    ])
  "
>
  <mat-icon matListIcon>face</mat-icon>
  <span
    *ngIf="emailForSubjectTotal > 0"
    matBadge="{{ emailForSubjectTotal }}"
    matBadgeOverlap="false"
    class="email-for-subject"
    >Email for Subject</span
  >
  <span *ngIf="emailForSubjectTotal <= 0">Email for Subject</span>
</a>
<a
  mat-list-item
  [routerLink]="['/email-for-address']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.ORDER_ASSISTANT
    ])
  "
>
  <mat-icon matListIcon>house</mat-icon>
  <span
    *ngIf="emailForAddressTotal > 0"
    matBadge="{{ emailForAddressTotal }}"
    matBadgeOverlap="false"
    class="email-for-address"
    >Email for Address</span
  >
  <span *ngIf="emailForAddressTotal <= 0">Email for Address</span>
</a>
<a
  mat-list-item
  [routerLink]="['/photo-upload-errors']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.ORDER_ASSISTANT])"
>
  <mat-icon matListIcon>broken_image</mat-icon>
  Photo Upload Errors
</a>

<a
  mat-list-item
  [routerLink]="['/refund-requests']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.CUSTOMER_SERVICE_MANAGER])"
>
  <mat-icon matListIcon>money_off</mat-icon>
  Refund Requests
</a>

<a
  mat-list-item
  [routerLink]="['/invoice-requests']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.CUSTOMER_SERVICE_MANAGER])"
>
  <mat-icon matListIcon>attach_money</mat-icon>
  Invoice Requests
</a>

<h3 matSubheader *ngIf="permissionsService.restrictToRoles([RoleId.INHOUSE_DESIGNER])">Design</h3>
<a
  mat-list-item
  [routerLink]="['/designs']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.INHOUSE_DESIGNER])"
>
  <mat-icon matListIcon>format_paint</mat-icon>
  All Designs
</a>
<a
  mat-list-item
  [routerLink]="['/special-treatment-designs']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.INHOUSE_DESIGNER])"
>
  <mat-icon matListIcon>notification_important</mat-icon>
  Special Treatments
</a>
<a
  mat-list-item
  *ngIf="permissionsService.user && permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.INHOUSE_DESIGNER])"
  [routerLink]="['/user-statistics/' + permissionsService.user.id]"
  [routerLinkActive]="['is-active']"
>
  <mat-icon matListIcon>show_chart</mat-icon>
  My Statistics
</a>

<h3 matSubheader *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.INHOUSE_DESIGNER])">Review</h3>
<a
  mat-list-item
  [routerLink]="['/review-designs']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.INHOUSE_DESIGNER])"
>
  <mat-icon matListIcon>thumbs_up_down</mat-icon>
  <div>Review Designs</div>
</a>

<h3
  matSubheader
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER, RoleId.SCATTERER])"
>
  Print
</h3>
<a
  mat-list-item
  [routerLink]="['/print']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon>print</mat-icon>
  Print
</a>
<a
  mat-list-item
  [routerLink]="['/pre-designed-print']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon>palette</mat-icon>
  Pre-designed print
</a>
<a
  mat-list-item
  [routerLink]="['/special-treatment-prints']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon>notification_important</mat-icon>
  Special treatments
</a>
<a
  mat-list-item
  [routerLink]="['/sample-prints']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon fontSet="material-symbols-outlined">labs</mat-icon>
  Sample
</a>
<a
  mat-list-item
  [routerLink]="['/bulk/packaging-prints']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon fontSet="material-symbols-outlined">package</mat-icon>
  Packaging
</a>
<a
  mat-list-item
  [routerLink]="['/print-download-log']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon>archive</mat-icon>
  Print Download Log
</a>
<a
  mat-list-item
  [routerLink]="['/print-upload-log']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER, RoleId.SCATTERER])"
>
  <mat-icon matListIcon>unarchive</mat-icon>
  Print Upload Log
</a>
<a
  mat-list-item
  [routerLink]="['/revert-production']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.PRINT_MANAGER])"
>
  <mat-icon matListIcon>undo</mat-icon>
  Revert Production
</a>

<h3 matSubheader *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SORTER])">Sorting</h3>
<a
  mat-list-item
  [routerLink]="['/sorting']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SORTER])"
>
  <mat-icon matListIcon>sort</mat-icon>
  Sorting
</a>

<h3
  matSubheader
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SHIPPING_MANAGER, RoleId.SHIPPER])"
>
  Shipping
</h3>
<a
  mat-list-item
  [routerLink]="['/shipping']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SHIPPING_MANAGER, RoleId.SHIPPER])"
>
  <mat-icon matListIcon>local_shipping</mat-icon>
  Shipping
</a>
<a
  mat-list-item
  [routerLink]="['/shipments']"
  [queryParams]="{ filterStatus: StatusId.SHIPPED, sortBy: 'shippedAt', sortDirection: 'desc' }"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.SUPERADMIN, RoleId.ADMIN])"
>
  <mat-icon><span class="material-symbols-outlined"> package_2 </span></mat-icon>&nbsp; Shipments
</a>
<a
  mat-list-item
  [routerLink]="['/shipment-on-hold']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SHIPPING_MANAGER])"
>
  <mat-icon matListIcon>pan_tool</mat-icon>
  Shipment on Hold
</a>
<a
  mat-list-item
  [routerLink]="['/stuck-in-shipping']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SHIPPING_MANAGER])"
>
  <mat-icon matListIcon>departure_board</mat-icon>
  Stuck in shipping
</a>
<a
  mat-list-item
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.SHIPPING_MANAGER,
      RoleId.RETAIL_MANAGER
    ])
  "
  [routerLink]="['/late-orders']"
  [queryParams]="{ filterStatus: 'all', filterLateOrders: 'true' }"
  [routerLinkActive]="['is-active']"
>
  <mat-icon matListIcon>history</mat-icon>
  Late Orders
</a>

<h3
  matSubheader
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.PRINT_MANAGER,
      RoleId.ORDER_ASSISTANT,
      RoleId.HR,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.SHIPPING_MANAGER
    ])
  "
>
  Reporting
</h3>
<a
  mat-list-item
  [routerLink]="['/status-overview']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER])"
>
  <mat-icon matListIcon>calendar_today</mat-icon>
  Status Overview
</a>
<a
  mat-list-item
  [routerLink]="['/export']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.PRINT_MANAGER,
      RoleId.ORDER_ASSISTANT,
      RoleId.HR,
      RoleId.CUSTOMER_SERVICE_MANAGER,
      RoleId.CUSTOMER_SERVICE,
      RoleId.SHIPPING_MANAGER
    ])
  "
>
  <mat-icon matListIcon>assessment</mat-icon>
  Export
</a>
<a
  mat-list-item
  [routerLink]="['/manifests']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SHIPPING_MANAGER])"
>
  <mat-icon matListIcon fontSet="material-symbols-outlined">barcode</mat-icon>
  Manifests
</a>
<a
  mat-list-item
  [routerLink]="['/shippingeasy']"
  [routerLinkActive]="['is-active']"
  *ngIf="
    permissionsService.restrictToRoles([RoleId.SHIPPINGEASY_UPLOADER, RoleId.PRINT_MANAGER, RoleId.SHIPPING_MANAGER])
  "
>
  <mat-icon matListIcon>local_shipping</mat-icon>
  ShippingEasy
</a>

<ng-container
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.FREE_SAMPLE_DESIGNER,
      RoleId.BULK_DESIGNER,
      RoleId.FREE_SAMPLE_APPROVER,
      RoleId.ACCOUNT_REP
    ])
  "
>
  <h3 matSubheader>Bulk Designs</h3>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
    [routerLink]="['/bulk-orders']"
    [queryParams]="defaultFiltersBulkOrders"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon matListIcon>receipt</mat-icon>
    Orders
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
    [routerLink]="['/bulk/customers']"
    [routerLinkActive]="['is-active']"
    [queryParams]="defaultFiltersBulkCustomers"
  >
    <mat-icon matListIcon>supervised_user_circle</mat-icon>
    Customers
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.ACCOUNT_REP])"
    [routerLink]="['/bulk/customer-followup']"
    [queryParams]="defaultFiltersCustomerFollowup"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon matListIcon>support_agent</mat-icon>
    Customer Followup
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.FREE_SAMPLE_APPROVER])"
    [routerLink]="['/bulk/conceptor-queue']"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon
      matListIcon
      *ngIf="permissionsService.restrictToRoles([RoleId.FREE_SAMPLE_APPROVER])"
      fontSet="material-symbols-outlined"
      >emoji_objects</mat-icon
    >
    Conceptor Queue
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.FREE_SAMPLE_DESIGNER])"
    [routerLink]="['/bulk/design-queue']"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon
      matListIcon
      *ngIf="permissionsService.restrictToRoles([RoleId.FREE_SAMPLE_DESIGNER])"
      fontSet="material-symbols-outlined"
      >stylus_note</mat-icon
    >
    Design Queue
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.FREE_SAMPLE_APPROVER])"
    [routerLink]="['/bulk/review-queue']"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon
      matListIcon
      *ngIf="permissionsService.restrictToRoles([RoleId.FREE_SAMPLE_APPROVER])"
      fontSet="material-symbols-outlined"
      >approval_delegation</mat-icon
    >
    Review Queue
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
    [routerLink]="['/bulk/designs']"
    [routerLinkActive]="['is-active']"
    [queryParams]="defaultFiltersBulkDesigns"
  >
    <mat-icon matListIcon>format_paint</mat-icon>
    Designs
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
    [routerLink]="['/bulk/packaging']"
    [routerLinkActive]="['is-active']"
    [queryParams]="defaultFiltersBulkDesigns"
  >
    <mat-icon matListIcon>format_paint</mat-icon>
    Packaging
  </a>
  <a
    mat-list-item
    [routerLink]="['/bulk/new-designs']"
    [queryParams]="{ filterStatuses: '1' }"
    [routerLinkActive]="['is-active']"
    [queryParams]="defaultFiltersBulkNewDesigns"
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
  >
    <mat-icon matListIcon fontSet="material-symbols-outlined">more_time</mat-icon>
    New Designs
  </a>
  <a
    mat-list-item
    [routerLink]="['/bulk/action-required']"
    [routerLinkActive]="['is-active']"
    [queryParams]="defaultFiltersBulkActionRequired"
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
  >
    <mat-icon matListIcon fontSet="material-symbols-outlined">engineering</mat-icon>
    Action Required
  </a>
  <a
    mat-list-item
    [routerLink]="['/bulk/unanswered-chat']"
    [routerLinkActive]="['is-active']"
    [queryParams]="defaultFiltersBulkUnansweredChat"
    *ngIf="permissionsService.restrictToRoles([RoleId.BULK_DESIGNER, RoleId.ACCOUNT_REP])"
  >
    <mat-icon matListIcon fontSet="material-symbols-outlined">mark_unread_chat_alt</mat-icon>
    Unanswered Chat
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([RoleId.ACCOUNT_REP])"
    [routerLink]="['/bulk-orders']"
    [queryParams]="defaultCommissionFilters"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon matListIcon>currency_exchange</mat-icon>
    Commissions
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([])"
    [routerLink]="['/bulk/pricing-rules']"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon matListIcon>rule</mat-icon>
    Pricing Rules
  </a>
  <a
    mat-list-item
    *ngIf="permissionsService.restrictToRoles([])"
    [routerLink]="['/bulk/customer-import']"
    [routerLinkActive]="['is-active']"
  >
    <mat-icon matListIcon>publish</mat-icon>
    Customer Import
  </a>
</ng-container>

<h3
  matSubheader
  *ngIf="
    permissionsService.restrictToRoles([
      RoleId.ORDER_MANAGER,
      RoleId.SHIPPING_MANAGER,
      RoleId.SHIPPINGEASY_UPLOADER,
      RoleId.PRINT_MANAGER,
      RoleId.AMAZON_UPLOADER,
      RoleId.SCATTERER,
      RoleId.HR
    ]) || permissionsService.isOuthouseManager()
  "
>
  Administration
</h3>
<a
  mat-list-item
  [routerLink]="['/settings']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.SHIPPING_MANAGER])"
>
  <mat-icon matListIcon>settings</mat-icon>
  Settings
</a>
<a
  mat-list-item
  [routerLink]="['/users']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.HR])"
>
  <mat-icon matListIcon>person</mat-icon>
  Users
</a>
<a
  mat-list-item
  [routerLink]="['/teams']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>group</mat-icon>
  Teams
</a>
<!--This will be shown to both admins and outhouse managers-->
<a
  mat-list-item
  [routerLink]="['/my-team']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([]) || permissionsService.isOuthouseManager()"
>
  <mat-icon matListIcon>group</mat-icon>
  My Team
</a>
<a
  mat-list-item
  [routerLink]="['/auth-log']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>lock</mat-icon>
  Auth log
</a>
<a
  mat-list-item
  [routerLink]="['/email-log']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>email</mat-icon>
  E-mail log
</a>
<a
  mat-list-item
  [routerLink]="['/queues']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>wc</mat-icon>
  Queues
</a>
<a
  mat-list-item
  [routerLink]="['/templates']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SCATTERER])"
>
  <mat-icon matListIcon>wallpaper</mat-icon>
  Templates
</a>
<a
  mat-list-item
  [routerLink]="['/scatter-settings']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.SCATTERER])"
>
  <mat-icon matListIcon>format_paint</mat-icon>
  Scatter settings
</a>
<a
  mat-list-item
  [routerLink]="['/amazon']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.AMAZON_UPLOADER])"
>
  <mat-icon matListIcon>sync</mat-icon>
  Amazon
</a>
<a
  mat-list-item
  [routerLink]="['/shipping-rules']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>rule</mat-icon>
  Shipping Rules
</a>
<a
  mat-list-item
  [routerLink]="['/ship-by']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>schedule_send</mat-icon>
  Ship By
</a>
<a
  mat-list-item
  [routerLink]="['/simple-crop-gallery']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>crop</mat-icon>
  Simple Crop Gallery
</a>
<a
  mat-list-item
  [routerLink]="['/simple-crop-release']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>draw</mat-icon>
  Simple Crop Release
</a>
<a
  mat-list-item
  [routerLink]="['/expedite']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>flash_on</mat-icon>
  Expedite
</a>
<a
  mat-list-item
  [routerLink]="['/reports']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([])"
>
  <mat-icon matListIcon>flag</mat-icon>
  Reports
</a>
<a
  mat-list-item
  [routerLink]="['/sock-builder']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([RoleId.ORDER_MANAGER, RoleId.SCATTERER])"
>
  <mat-icon matListIcon>bubble_chart</mat-icon>
  Sock Builder
</a>

<h3 matSubheader *ngIf="permissionsService.restrictToRoles([], false)">Superadmin tools</h3>
<a
  mat-list-item
  [routerLink]="['/api-keys']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([], false)"
>
  <mat-icon matListIcon>vpn_key</mat-icon>
  API Keys
</a>
<a
  mat-list-item
  [routerLink]="['/add-settings']"
  [routerLinkActive]="['is-active']"
  *ngIf="permissionsService.restrictToRoles([], false)"
>
  <mat-icon matListIcon>add_to_queue</mat-icon>
  Create Settings
</a>

<div id="google_translate_element"></div>
