import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../models/user.model';
import { PermissionsService } from '../../services/permissions.service';
import { Router } from '@angular/router';
import { OrderProduct } from '../../models/order-product.model';
import { StatusId } from '../../models/status.model';
import { OrderProductsService } from '../../services/order-products.service';
import { Subject } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { RoleId } from '../../models/role.model';
import { OrderProductPhotosService } from '../../services/order-product-photos.service';
import { OrderProductPhoto } from '../../models/order-product-photo.model';
import { WaitingForCustomerReasonId } from '../../models/waiting-for-customer-reason.model';
import { CustomerServiceService } from '../../services/customer-service.service';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';
import { BulkDesignRequestStatusId } from 'src/app/models/bulk-design-request-status';
import { filter } from 'jszip';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public RoleId: any = RoleId;
  public StatusId: any = StatusId;
  public BulkDesignRequestStatusId: any = BulkDesignRequestStatusId;
  @Input() public user: User;
  private unsubscribe: Subject<void> = new Subject<void>();

  public expeditedOverviewTotal: number = 0;
  public needsNoteReviewTotal: number = 0;
  public specialTreatmentOverviewTotal: number = 0;
  public customerServiceTotal: number = 0;
  public emailForPictureTotal: number = 0;
  public emailForSubjectTotal: number = 0;
  public emailForAddressTotal: number = 0;
  public createOrderAccessUsers;

  public defaultFiltersBulkOrders: any = {
    filterSources: 'b2b',
    filterStatus: `draft,processing`
  };
  public defaultCommissionFilters: any = {
    preset: 'commission',
    filterSources: 'b2b',
    filterStatus: `shipped`
  };
  public defaultFiltersBulkCustomers: any = {};
  public defaultFiltersBulkDesigns: any = {};
  public defaultFiltersBulkNewDesigns: any = {
    filterStatuses: BulkDesignRequestStatusId.PENDING
  };
  public defaultFiltersBulkActionRequired: any = {
    filterStatuses: this.b2bActionRequiredStatuses()
  };
  public defaultFiltersBulkUnansweredChat: any = {
    filterUnansweredChat: '1'
  };
  public defaultFiltersCustomerFollowup: any = {
    filterOutreachCooldownExpired: 'true'
  };

  constructor(
    public permissionsService: PermissionsService,
    private ordersService: OrdersService,
    private orderProductsService: OrderProductsService,
    private orderProductPhotosService: OrderProductPhotosService,
    private customerServiceService: CustomerServiceService,
    private settingsService: SettingsService,
    private router: Router
  ) {}

  public async ngOnInit() {
    // this.initSocketEndpoints();

    // this.updateExpeditedOverviewBadge();
    // this.updateNeedsNoteReviewBadge();
    // this.updateSpecialTreatmentOverviewBadge();
    // this.updateCustomerServiceBadge();
    // this.updateEmailForPictureBadge();

    if (this.permissionsService.restrictFromRoles([RoleId.SUPERADMIN, RoleId.ADMIN])) {
      this.createOrderAccessUsers = (await this.settingsService.find({})).find(
        (s) => s.name === 'create-order-access-users'
      ).value.users;
    }

    if (this.permissionsService.user.roleIds.includes(RoleId.ACCOUNT_REP)) {
      this.defaultFiltersBulkOrders.filterAccountReps = this.permissionsService.user.id;
      this.defaultFiltersBulkCustomers.filterAccountReps = this.permissionsService.user.id;
      this.defaultFiltersBulkDesigns.filterAccountReps = this.permissionsService.user.id;
      this.defaultFiltersBulkNewDesigns.filterAccountReps = this.permissionsService.user.id;
      this.defaultFiltersBulkActionRequired.filterAccountReps = this.permissionsService.user.id;
      this.defaultFiltersBulkUnansweredChat.filterAccountReps = this.permissionsService.user.id;
      this.defaultFiltersCustomerFollowup.filterAccountReps = this.permissionsService.user.id;
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initSocketEndpoints(): void {
    if (
      this.permissionsService.restrictToRoles([
        RoleId.ORDER_MANAGER,
        RoleId.CUSTOMER_SERVICE_MANAGER,
        RoleId.CUSTOMER_SERVICE
      ])
    ) {
      this.ordersService
        .onOrderCreated()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async (newOrderProduct: OrderProduct) => {
          this.updateExpeditedOverviewBadge();
          this.updateSpecialTreatmentOverviewBadge();
        });

      this.orderProductsService
        .onOrderProductUpdated()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((updatedOrderProduct: OrderProduct) => {
          this.updateExpeditedOverviewBadge();
          this.updateNeedsNoteReviewBadge();
          this.updateSpecialTreatmentOverviewBadge();
          this.updateCustomerServiceBadge();
          this.updateEmailForPictureBadge();
        });

      this.orderProductPhotosService
        .onOrderProductPhotoUpdated()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((updatedOrderProductPhoto: OrderProductPhoto) => {
          this.updateEmailForPictureBadge();
        });
    }
  }

  private async updateExpeditedOverviewBadge(): Promise<void> {
    if (
      this.permissionsService.restrictToRoles([
        RoleId.ORDER_MANAGER,
        RoleId.CUSTOMER_SERVICE_MANAGER,
        RoleId.CUSTOMER_SERVICE
      ])
    ) {
      const response = await this.orderProductsService.find({
        query: {
          expedited: 1,
          statusId: {
            $nin: [StatusId.SHIPPED, StatusId.CANCELED]
          },
          $limit: 0
        }
      });

      this.expeditedOverviewTotal = response.total;
    }
  }

  private async updateNeedsNoteReviewBadge(): Promise<void> {
    if (this.permissionsService.restrictToRoles([RoleId.ORDER_MANAGER])) {
      const response = await this.orderProductsService.find({
        query: {
          statusId: StatusId.NEEDS_NOTE_REVIEW,
          waitingForCustomer: 'null',
          $limit: 0
        }
      });

      this.needsNoteReviewTotal = response.total;
    }
  }

  private async updateSpecialTreatmentOverviewBadge(): Promise<void> {
    if (
      this.permissionsService.restrictToRoles([
        RoleId.ORDER_MANAGER,
        RoleId.CUSTOMER_SERVICE_MANAGER,
        RoleId.CUSTOMER_SERVICE
      ])
    ) {
      const response = await this.orderProductsService.find({
        query: {
          specialTreatment: 1,
          statusId: {
            $nin: [StatusId.SHIPPED, StatusId.CANCELED]
          },
          $limit: 0
        }
      });

      this.specialTreatmentOverviewTotal = response.total;
    }
  }

  private async updateCustomerServiceBadge(): Promise<void> {
    if (
      this.permissionsService.restrictToRoles([RoleId.CUSTOMER_SERVICE, RoleId.CUSTOMER_SERVICE_MANAGER], false, false)
    ) {
      // For customer service, get their number of todos
      const customerServiceResponse = await this.customerServiceService.getTodo({
        query: {
          $limit: 0
        }
      });

      this.customerServiceTotal = customerServiceResponse.total;
    } else if (this.permissionsService.restrictToRoles([RoleId.ORDER_MANAGER])) {
      // For admins, get the total number of OPs that are WFC
      const customerServiceResponse = await this.orderProductsService.find({
        query: {
          waitingForCustomer: {
            $ne: 'null'
          },
          statusId: {
            $nin: [StatusId.SHIPPED, StatusId.CANCELED]
          },
          $limit: 0
        }
      });

      this.customerServiceTotal = customerServiceResponse.total;
    }
  }

  private async updateEmailForPictureBadge(): Promise<void> {
    if (
      this.permissionsService.restrictToRoles([
        RoleId.ORDER_MANAGER,
        RoleId.CUSTOMER_SERVICE_MANAGER,
        RoleId.CUSTOMER_SERVICE
      ])
    ) {
      const orderProductsResponse = await this.orderProductsService.find({
        query: {
          statusId: StatusId.BAD_PHOTO,
          $or: [
            {
              waitingForCustomer: {
                $ne: WaitingForCustomerReasonId.BAD_PHOTO
              }
            },
            {
              waitingForCustomer: 'null'
            }
          ],
          $limit: -1
        }
      });

      let total = 0;

      orderProductsResponse.forEach((orderProduct: OrderProduct) => {
        const badPhotosNotContacted = orderProduct.orderProductPhotos.filter((orderProductPhoto: OrderProductPhoto) => {
          return orderProductPhoto.active && orderProductPhoto.badPhotoReason && !orderProductPhoto.contactedCustomer;
        });

        total += badPhotosNotContacted.length;
      });

      this.emailForPictureTotal = total;
    }
  }

  public b2bActionRequiredStatuses(): string {
    return `${BulkDesignRequestStatusId.PENDING},${BulkDesignRequestStatusId.AWAITING_CONCEPT},${BulkDesignRequestStatusId.AWAITING_DESIGN},${BulkDesignRequestStatusId.DESIGN},${BulkDesignRequestStatusId.AWAITING_APPROVAL},${BulkDesignRequestStatusId.REVISION_REQUESTED},${BulkDesignRequestStatusId.REVISION},${BulkDesignRequestStatusId.AWAITING_SEND_TO_CUSTOMER}`;
  }
}
